<template>
	<!-- Under maintenance-->
	<div class="misc-wrapper">
		<b-link class="brand-logo">
			<vuexy-logo />

			<h2 class="brand-text text-primary ml-1">高性能智能建筑系统</h2>
		</b-link>

		<div class="misc-inner p-2 p-sm-3">
			<div class="w-100 text-center">
				<h2 class="mb-1">系统正在维护 🛠</h2>

				<p class="mb-3">很抱歉，当前系统正在维护，我们正在抓紧修复。</p>

				<b-button :to="{ path: '/login' }" class="my-2 btn-sm-block" variant="primary">返回首页</b-button>
			</div>
			<!-- img -->
			<b-img :src="imgUrl" alt="Under maintenance page" fluid />
		</div>
	</div>
	<!-- / Under maintenance-->
</template>

<script>
	/* eslint-disable global-require */
	import VuexyLogo from "@/@core/layouts/components/Logo.vue";
	import store from "@/store";

	export default {
		components: {
			VuexyLogo,
		},
		data() {
			return {
				downImg: require("@/assets/images/pages/under-maintenance.svg"),
			};
		},
		computed: {
			imgUrl() {
				if (store.state.appConfig.layout.skin === "dark") {
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.downImg = require("@/assets/images/pages/under-maintenance-dark.svg");
					return this.downImg;
				}
				return this.downImg;
			},
		},
	};
</script>

<style lang="scss">
	@import "src/@core/scss/vue/pages/page-misc.scss";
</style>
